import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import SEO from "components/SEO"
import ContentBlock from "components/ContentBlock"
import ContentImage from "components/ContentImage"
import PageHero from "components/PageHero"

const PageTemplate = props => {
  const { hero, blocks, seo } = props.data.wordpressPage

  return (
    <Layout location={props.location} default navigation>
      <SEO seo={seo} />
      <PageHero details={hero} />
      {blocks.map((item, i) => {
        switch (item.type) {
          case "content":
            return (
              <ContentBlock
                key={i}
                large
                title={item.block.title}
                content={item.block.content}
              />
            )
          case "imageLeft":
          case "imageCenter":
          case "imageRight":
            return (
              <ContentImage
                key={i}
                image={item.block.image}
                justify="baseline"
                type={item.block.align}
              />
            )
          default:
        }
      })}
    </Layout>
  )
}

export default PageTemplate

export const defaultPageQuery = graphql`
  query PageDefaultByID($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    wordpressPage(id: { eq: $id }) {
      slug
      template
      wordpress_id
      blocks {
        type
        block {
          title
          content
          align
          image {
            alt
            src {
              id
              path
            }
            title
          }
        }
      }
      hero {
        subtitle
        title {
          invert
          text
        }
        image {
          src {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                  srcSetBreakpoints: [400, 1024, 1280, 1921]
                  maxWidth: 1921
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
      seo {
        title
        siteName
        description
        image {
          src {
            localFile {
              childImageSharp {
                fixed(width: 1280, height: 853, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
      id
    }
  }
`
